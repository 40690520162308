<template>
  <CContainer
    class="grid grid-cols-4 md:gap-6 auto-rows-auto"
  >
    <div class="hidden lg:block col-span-1">
      <CNavSidebar class="sticky top-[142px]">
        <CNavSidebarItem to="/m/settings/shop/general">
          {{ $t('general') }}
        </CNavSidebarItem>
        <CNavSidebarItem to="/m/settings/shop/delivery">
          {{ $t('delivery') }}
        </CNavSidebarItem>
        <CNavSidebarItem to="/m/settings/shop/certificates">
          {{ $t('certificates') }}
        </CNavSidebarItem>
        <CNavSidebarItem to="/m/settings/shop/placement">
          {{ $t('placementNotes') }}
        </CNavSidebarItem>
        <CNavSidebarItem to="/m/settings/shop/page/impressum">
          {{ $t('imprint') }}
        </CNavSidebarItem>
        <CNavSidebarItem to="/m/settings/shop/page/datenschutz">
          {{ $t('privacy') }}
        </CNavSidebarItem>
        <CNavSidebarItem to="/m/settings/shop/page/widerruf">
          {{ $t('cancellation') }}
        </CNavSidebarItem>
        <CNavSidebarItem to="/m/settings/shop/page/agb">
          {{ $t('terms') }}
        </CNavSidebarItem>
      </CNavSidebar>
    </div>
    <div class="col-span-4 lg:col-span-3">
      <router-view />
    </div>
  </CContainer>
</template>

<script>
import { GET_PAGES, GET_SHOP } from '@/store/actionTypes';

export default {
  created() {
    this.$store.dispatch(GET_SHOP);
    this.$store.dispatch(GET_PAGES);
  },
};
</script>
